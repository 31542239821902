"use client";
// Core
import cx from "classnames";

// Components
import { Button as AntButton } from "antd";
import { Tooltip } from "../Tooltip";

// Definitions
import type { ReactNode } from "react";
import type { TooltipPlacement } from "antd/es/tooltip";
import type {
  BaseButtonProps as AntBaseButtonProps,
  ButtonProps as AntButtonProps,
} from "antd/es/button/button";
import type { ButtonHTMLType as AntButtonHTMLType } from "antd/es/button/buttonHelpers";
import type { SizeType } from "antd/es/config-provider/SizeContext";

// Utils
import st from "./styles.module.css";

export type ButtonGeneralType = AntButtonProps["type"];
export type ButtonCustomType =
  | "icon"
  | "icon-active"
  | "table-link"
  | "table-link-disabled"
  | "cart"
  | "download-pdf"
  | "intro-navs"
  | "delete-photo"
  | "transparent";
export type ButtonSizeType = SizeType;
export type ButtonHTMLType = AntButtonHTMLType;
type ButtonMode = "button" | "custom";

export type ButtonProps = AntButtonProps &
  AntBaseButtonProps & {
    type?: ButtonGeneralType;
    mode?: ButtonMode;
    extraLarge?: boolean;
    noStyle?: boolean;
    htmlType?: ButtonHTMLType;
    size?: ButtonSizeType;
    btnType?: ButtonCustomType;
    hasTooltip?: boolean;
    showTooltip?: boolean;
    tooltipPlacement?: TooltipPlacement;
    tooltipContent?: ReactNode;
  };

export const Button = (props: ButtonProps) => {
  const {
    children,
    className = "",
    extraLarge = false,
    noStyle,
    btnType,
    hasTooltip = false,
    showTooltip = false,
    tooltipPlacement,
    tooltipContent = null,
    type,
    mode = "button",
    onClick,
    ...rest
  } = props;

  const handleClick = (evt: React.MouseEvent<HTMLElement>) => {
    if (mode === "custom") {
      evt.preventDefault();
      return onClick?.(evt);
    }
    return onClick?.(evt);
  };

  const btnStyle = cx(
    st.btn,
    {
      [st["extra-large"]]: extraLarge,
      [st["no-style"]]: noStyle,
    },
    btnType && { [st[btnType]]: true },
    className,
  );

  const baseJSX = (
    <AntButton className={btnStyle} type={type} onClick={handleClick} {...rest}>
      {children}
    </AntButton>
  );

  return hasTooltip ? (
    <Tooltip
      overlayClassName={st.tooltip}
      placement={tooltipPlacement}
      title={tooltipContent}
      destroyTooltipOnHide
      {...(showTooltip && { open: true })}
    >
      {baseJSX}
    </Tooltip>
  ) : (
    baseJSX
  );
};
